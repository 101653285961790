//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "home",
  data() {
    return {
      tipoValidacion: this.$route.query.tipo,
      caja: JSON.parse(localStorage.getItem("cajaObj")),
      usuario: localStorage.getItem("usuario"),
    }
  },
  methods: {
    cerrarSession() {
      let token = JSON.stringify({pin:localStorage.getItem("pin"),cajaId:localStorage.getItem("cajaId"),tituloLogin:localStorage.getItem("tituloLogin")});
      localStorage.clear();
      this.$router.push({name: "login", query: {token: btoa(token)}});
    }
  }
}
